<template>
  <div class="team-info" :class="{ 'team-info-away': !isHomeTeam }">
    <div class="team-info__logo" :class="logoClass">
      <img :src="`${teamsLogosUrl}${team.team_id}.png`" alt="" />
    </div>
    <div class="team-info__name">
      <div class="team-info__name__text">
        {{ team.acronym }}
      </div>
      <div v-if="isProfileButtonVisible" class="team-info__name__profile">
        <ProfileButton @click="$emit('click-profile')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CarouselTeamInfo',
  components: {
    ProfileButton: () => import('@/components/Sections/GolTipster-v2/General/ProfileButton'),
  },
  props: {
    isHomeTeam: {
      type: Boolean,
      default: true,
    },
    team: {
      type: Object,
      default: () => {},
    },
    isProfileButtonVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      teamsLogosUrl: 'https://az755631.vo.msecnd.net/teams-150/',
    };
  },
  computed: {
    logoClass() {
      return [this.isHomeTeam ? 'team-info__logo-home' : 'team-info__logo-away'];
    },
    textClass() {
      return [this.isHomeTeam ? 'team-info__text-home' : 'team-info__text-away'];
    },
  },
};
</script>

<style scoped lang="scss">
.team-info {
  display: flex;
  font-family: Heebo, sans-serif;

  &-away {
    flex-direction: row-reverse;
  }

  &__logo {
    height: 7.75rem;

    & > img {
      height: 100%;
      width: auto;
      object-fit: fill;
    }

    &-home {
      filter: drop-shadow(-12px 19px 6px rgba(18, 21, 47, 0.51));
    }

    &-away {
      filter: drop-shadow(12px 19px 6px rgba(18, 21, 47, 0.51));
    }

    @media screen and (max-width: 1028px) {
      height: 5rem;
    }

    @media screen and (max-width: 530px) {
      height: 3.5rem;
    }
  }

  &__name {
    display: flex;
    flex-direction: column;
    margin: 0 0.8rem;
    align-items: center;

    &__text {
      font-family: Roboto-Bold, sans-serif;
      color: white;
      font-size: 3.5rem;
      line-height: 0.71;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      margin: 1.2rem 0 1rem;

      &-home {
        text-align: left;
      }

      &-away {
        text-align: right;
      }

      @media screen and (max-width: 986px) {
        font-size: 2.5rem;
      }

      @media screen and (max-width: 530px) {
        font-size: 1.8rem;
      }

      @media screen and (max-width: 422px) {
        font-size: 1.4rem;
      }
    }

    @media screen and (max-width: 530px) {
      margin: 0 0.4rem;
    }

    @media screen and (max-width: 386px) {
      display: none;
    }
  }
}
</style>
